import React from 'react';
import css from './styles.module.scss';
import cn from 'classnames';

const TermsComponent = ({ value, onChange, error, text, align }) => {
  if (text) {
    return (
      <div
        className={cn(
          css.checkboxStyles,
          { [css.hasError]: error },
          { [css.align]: align }
        )}
      >
        <input
          className={css.checkboxStyles__square}
          type={'checkbox'}
          id="checkbox-id"
          checked={value}
          onChange={onChange}
        />
        <label htmlFor="checkbox-id">{text}</label>
      </div>
    );
  }
  return (
    <div className={cn(css.checkboxStyles, { [css.hasError]: error })}>
      <input
        className={css.checkboxStyles__square}
        type={'checkbox'}
        id="checkbox-id"
        checked={value}
        onChange={onChange}
      />
      <label htmlFor="checkbox-id">
        I have read and agreed to the{' '}
        <a
          href="https://guardarian.com/terms-of-service"
          className={css.checkboxStyles__link}
        >
          Terms of service
        </a>
        .
      </label>
    </div>
  );
};

export default TermsComponent;
