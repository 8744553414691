import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { getVerifiedWallets } from '../../../store/slices/calculatorSlice';
import SelectorZeroState from '../SelectorZeroState/SelectorZeroState';

function WalletSelector({ wallets, selectedWallet, onChange }) {
  return (
    <select
      className={styles.selector}
      value={selectedWallet}
      onChange={onChange}
      defaultValue={wallets[0]?.id}
      style={{ backgroundImage: 'url(/images/dropdown_arrow.svg)' }}
    >
      {wallets.map((wallet) => {
        return <option value={wallet.id}>{wallet.address}</option>;
      })}
    </select>
  );
}

export default WalletSelector;
