import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Wallet.module.scss';

import { getVerifiedWallets } from '../../store/slices/calculatorSlice';
import WalletSelector from './WalletSelector/WalletSelector';

import { Button } from '../Button';
import { selectWalletsByTicker } from '../../store/slices/selectors';
import SelectorZeroState from './SelectorZeroState/SelectorZeroState';
import TermsComponent from '../TermsComponent/TermsComponent';

export const WalletsSelection = ({
  ticker,
  selectedWallet,
  setWalletId,
  onChange,
  buttonClassName,
  buttonAction,
  buttonLoader,
  cryptoCurrency,
  title,
  titleMobile,
}) => {
  const dispatch = useDispatch();

  const walletsList = useSelector((state) =>
    selectWalletsByTicker(state, ticker)
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isWalletsLoading, setIsWalletsLoading] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [agreementError, setAgreementError] = useState(false);

  const handleAddWalletClick = (type) => () => {
    type === 'FIAT'
      ? window.location.assign('/b2b/payment-details/crypto-wallets')
      : window.location.assign('/b2b/payment-details/bank-accounts');
  };

  useEffect(() => {
    (async () => {
      await dispatch(getVerifiedWallets());
      setIsWalletsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!isWalletsLoading) {
      setWalletId(walletsList[0]?.id);
      setIsLoading(false);
    }
  }, [isWalletsLoading]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.header}>{title}</h2>
      <h2 className={styles.headerMobile}>{titleMobile}</h2>
      {!isLoading && walletsList.length > 0 && (
        <>
          <WalletSelector
            wallets={walletsList}
            onChange={onChange}
            selectedWallet={selectedWallet}
          />
          <TermsComponent
            value={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            error={agreementError}
            align={cryptoCurrency.currencyType === 'CRYPTO'}
            text={
              <>
                {cryptoCurrency.currencyType === 'FIAT' && (
                  <div>I have full access to the selected wallet address.</div>
                )}
                {cryptoCurrency.currencyType === 'CRYPTO' && (
                  <>
                    <div style={{ maxWidth: 450 }}>
                      All transfers should be performed only to your company
                      bank account. If you want to add a new bank account
                      <a
                        style={{ color: '#4c9de8' }}
                        href="mailto:sales@guardarian.com"
                      >
                        &nbsp;please contact us.
                      </a>
                    </div>
                  </>
                )}
              </>
            }
          />
          <Button
            fullWidth
            size="md"
            outline="none"
            green
            id={'sell-button'}
            className={buttonClassName}
            onClick={() => {
              if (termsAccepted) {
                buttonAction();
              } else {
                setAgreementError(true);
              }
            }}
          >
            {buttonLoader}
          </Button>
        </>
      )}
      {!isLoading && (
        <Button
          rounded={false}
          fullWidth
          outline="none"
          noBorder
          className={styles.wrapper__addButton}
          onClick={handleAddWalletClick(cryptoCurrency.currencyType)}
        >
          {cryptoCurrency.currencyType === 'FIAT'
            ? 'Add wallet'
            : 'Add bank account'}
        </Button>
      )}
      {!isLoading &&
        !walletsList.length &&
        !selectedWallet &&
        selectedWallet !== '' && (
          <>
            {cryptoCurrency.currencyType === 'FIAT' && <SelectorZeroState />}
            {cryptoCurrency.currencyType === 'CRYPTO' && (
              <div className={styles.wrapper__addWalletText}>
                If you want to add a new bank account
                <a href="mailto:sales@guardarian.com">
                  &nbsp;please contact us.
                </a>
              </div>
            )}
          </>
        )}
    </div>
  );
};
