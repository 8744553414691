import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWalletsList } from '../../api/content-api';

const initialState = {
  walletsList: [],
  estimatedData: null,
};

export const getVerifiedWallets = createAsyncThunk(
  'calculator/getWalletsList',
  async (_, thunkAPI) => {
    return await getWalletsList();
  }
);

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setEstimatedData: (state, action) => {
      state.estimatedData = action.payload;
    },
    resetEstimatedData: (state) => {
      state.estimatedData = initialState.estimatedData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVerifiedWallets.fulfilled, (state, action) => {
      state.walletsList = action.payload;
    });
  },
});

export const { setEstimatedData, resetEstimatedData } = calculatorSlice.actions;

export default calculatorSlice.reducer;
