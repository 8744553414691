import styles from './styles.module.scss';


function SelectorZeroState() {
    return (
        <p className={styles.errorNoWallet}>You dont have wallets please add it</p>
    );
  }
  
  export default SelectorZeroState;
  