import axios from 'axios';
import { roundMinMax } from './utils';
import { xApiKey } from './constants';

export async function cryptoCurrencies() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/b2b/currencies`, {
        headers: {
          'x-api-key': xApiKey,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods':
            'OPTIONS, DELETE, POST, GET, PATCH, PUT',
          'Access-Control-Max-Age': '3600',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          Referer: window.location.href,
        },
      })
      .then(({ data: { crypto_currencies } }) => resolve(crypto_currencies))
      .catch(() => reject());
  });
}

export async function estimate({
  from_amount,
  from_currency,
  to_currency,
  from_network,
  to_network,
  setError = () => {},
  onSuccess = () => {},
  setIsLoading = () => {},
  minMaxRange,
  setMinMaxRange = () => {},
  setFromAmountError = () => {},
}) {
  let range = { ...minMaxRange };
  const amount = parseFloat(from_amount);
  setIsLoading(true);

  if (
    range?.fromCurrency?.toLowerCase() !== from_currency?.toLowerCase() ||
    range?.toCurrency?.toLowerCase() !== to_currency?.toLowerCase()
  ) {
    range = await getMinMaxRange(
      from_currency,
      to_currency,
      from_network,
      to_network,
      xApiKey
    );
    setMinMaxRange(range || {});
  }

  if (
    range?.min &&
    range?.max &&
    (from_amount < parseFloat(range.min) || from_amount > parseFloat(range.max))
  ) {
    setFromAmountError(true);

    return;
  }
  setFromAmountError(false);

  if (amount) {
    try {
      const params = {
        toCurrency: to_currency.toUpperCase(),
        fromAmount: amount,
        fromCurrency: from_currency.toUpperCase(),
        fromNetwork: from_network.toUpperCase(),
        toNetwork: to_network.toUpperCase(),
      };

      if (from_network) {
        Object.assign(params, { fromNetwork: from_network });
      }

      if (to_network) {
        Object.assign(params, { toNetwork: to_network });
      }

      const { data } = await axios.post(`/b2b/estimate`, params, {
        headers: {
          'x-api-key': xApiKey,
        },
      });
      onSuccess(data);
      setError(false);
      setFromAmountError(false);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      }
    }
  }

  setIsLoading(false);
}

export async function createTransaction({
  from_amount,
  from_currency,
  to_amount,
  to_currency,
  crypto_wallet_id,
  fiat_wallet_id,
  from_network,
  to_network,
  setIsLoading,
  setError,
}) {
  setIsLoading(true);
  setError('');

  try {
    const params = {
      from_amount: parseFloat(from_amount),
      from_currency: from_currency.toUpperCase(),
      from_network,
      to_network,
      to_currency: to_currency.toUpperCase(),
      to_amount: parseFloat(to_amount),
      crypto_account_id: parseFloat(crypto_wallet_id),
    };

    if (fiat_wallet_id) {
      Object.assign(params, { fiat_account_id: parseFloat(fiat_wallet_id) });
    }

    if (from_network) {
      Object.assign(params, { from_network });
    }

    if (to_network) {
      Object.assign(params, { to_network });
    }

    const { data } = await axios.post(`/b2b/exchange`, params, {
      headers: {
        'x-api-key': xApiKey,
      },
    });

    if (data.redirect_url) {
      window.parent.location.href = data.redirect_url;
    }
  } catch (error) {
    const { data } = error.response;

    setError(data.message || 'Unexpected error occurred');
  }

  setIsLoading(false);
}

function isAddNetworkToRange(currency, network) {
  return network && network !== currency ? `-${network.toLowerCase()}` : '';
}

export async function getMinMaxRange(
  fromCurrency,
  toCurrency,
  fromNetwork,
  toNetwork
) {
  try {
    const { data } = await axios.get(
      `/b2b/min-max/${fromCurrency.toLowerCase()}${isAddNetworkToRange(
        fromCurrency,
        fromNetwork
      )}_${toCurrency.toLowerCase()}${isAddNetworkToRange(
        toCurrency,
        toNetwork
      )}`,
      {
        headers: {
          'x-api-key': xApiKey,
        },
      }
    );

    return {
      fromCurrency: data.from,
      toCurrency: data.to,
      min: roundMinMax(data.min),
      max: roundMinMax(data.max, true),
    };
  } catch (error) {
    console.error(error.response);

    return null;
  }
}
