import css from './ErrorMessage.module.scss';

const ErrorMessage = ({ range, amountSetter, fromAmount }) => (
    <p className={css.errorWrapper} onClick={() => amountSetter(fromAmount > range.max ? range.max : range.min)}>
        {fromAmount > range.max ? 'Max' : 'Min'} amount is{' '}
        <span className={css.underlinedText}>
      {fromAmount > range.max ? range.max : range.min} {range.fromCurrency}
    </span>
    </p>
);

export { ErrorMessage };
