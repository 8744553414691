import axios from 'axios';
import { xApiKey } from '../components/Calculator/constants';

export const getWalletsList = async () => {
  const { data } = await axios.get('/b2b/payment-details/list', {
    headers: {
      'x-api-key': xApiKey,
    },
  });

  return data;
};
