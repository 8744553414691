import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

const DetailsRow = ({ label, value, noBorder }) => {
  return (
    <div className={cn(styles.wrapper, { [styles.noBorder]: noBorder })}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </div>
  );
};

export default DetailsRow;
