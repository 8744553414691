import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import cn from 'classnames';
import { Button } from '../Button';
import Select from '../Select';
import { CurrencyItem } from '../Select/CurrencyItem';
import { CombinedInput } from '../CombinedInput';
import { ErrorMessage } from '../ErrorMessage';
import { formatNumber } from './utils';
import {
  cryptoNumberRegex,
  KYB_STATUSES,
  maxFiatValueLength,
  numberRegex,
} from './constants';
import { createTransaction, estimate } from './action';
import css from './Calculator.module.scss';
import { WalletsSelection } from '../WalletSelection/WalletSelection';
import TransactionDetails from '../TransactionDetails/TransactionDetails';
import { Loader } from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetEstimatedData,
  setEstimatedData,
} from '../../store/slices/calculatorSlice';

const estimateDebounced = debounce(estimate, 400);

const Calculator = ({ className, urlParams = {}, kybStatus }) => {
  const [fiatAmount, setFiatAmount] = useState('');
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [isCreatingExchange, setIsCreatingExchange] = useState(false);
  const [fiatCurrenciesList, setFiatCurrenciesList] = useState([]);
  const [cryptoCurrenciesList, setCryptoCurrenciesList] = useState([]);
  const [currenciesList, setCurrenciesList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState(null);
  const [cryptoCurrency, setCryptoCurrency] = useState(null);
  const [error, setError] = useState('');
  const [fromAmountError, setFromAmountError] = useState(false);
  const [minMaxRange, setMinMaxRange] = useState({});
  const [cryptoWalletId, setCryptoWalletId] = useState('');
  const [fiatWalletId, setFiatWalletId] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [agreementError, setAgreementError] = useState(false);
  const [walletPage, setWalletPage] = useState(false);

  const dispatch = useDispatch();

  const { estimatedData } = useSelector((state) => state.calculator);

  const fiatHandlers = {
    input: {
      value: fiatAmount,
      onChange: validateAndSetFiat,
    },
    select: {
      list: fiatCurrenciesList,
      onSelect: selectFiat,
      selected: fiatCurrency,
      disabled: fiatCurrenciesList.length <= 1,
    },
    error: {
      amountSetter: setFiatAmount,
    },
  };

  const cryptoHandlers = {
    input: {
      value: cryptoAmount,
      onChange: validateAndSetCrypto,
    },
    select: {
      list: cryptoCurrenciesList,
      onSelect: selectCrypto,
      selected: cryptoCurrency,
      disabled: cryptoCurrenciesList.length <= 1,
    },
    error: {
      amountSetter: setCryptoAmount,
    },
  };

  const getFiltredCurrencyList = (list) => list.filter((currency) => currency);

  const filterPaymentMethods = (handlers) => ({
    ...handlers,
    select: {
      ...handlers.select,
      list: getFiltredCurrencyList(handlers.select.list),
    },
  });

  const formObj = {
    from_currency: filterPaymentMethods(cryptoHandlers),
    to_currency: filterPaymentMethods(fiatHandlers),
  };

  const from_amount = parseFloat(formObj.from_currency.input.value) || 0;
  const from_network = formObj.from_currency.select.selected?.network;
  const to_network = formObj.to_currency.select.selected?.network;
  const to_amount = estimatedData?.value || 0;

  const from_currency = formObj.from_currency.select.selected?.id;
  const to_currency = formObj.to_currency.select.selected?.id;

  function validateAndSetFiat(e) {
    const value = e.target.value.replace(',', '.');

    if (numberRegex.test(value) && value.length <= maxFiatValueLength) {
      setFiatAmount(value);
    }
  }

  function validateAndSetCrypto(e) {
    const value = e.target.value.replace(',', '.');

    if (cryptoNumberRegex.test(value)) {
      setCryptoAmount(value);
    }
  }

  function selectFiat(option) {
    const foundFiatCurrency = fiatCurrenciesList.find((f) => {
      if (option.network) {
        return f.id === option.id && f.network === option.network;
      }
      return f.id === option.id;
    });

    setFiatCurrency(foundFiatCurrency);
    setFiatAmount(foundFiatCurrency.default_exchange_value);
  }

  function selectCrypto(option) {
    const foundCryptoCurrency = cryptoCurrenciesList.find((f) => {
      if (option.network) {
        return f.id === option.id && f.network === option.network;
      }
      return f.id === option.id;
    });

    setCryptoCurrency(foundCryptoCurrency);
  }

  const onChangeHandler = (e) => {
    setCryptoWalletId(e.target.value);
  };

  async function onSubmit() {
    if (!termsAccepted) {
      setAgreementError(true);
      return;
    }

    setAgreementError(false);

    if (!to_amount) {
      return;
    }

    await createTransaction({
      from_amount,
      from_currency,
      to_amount,
      to_currency,
      from_network,
      to_network,
      crypto_wallet_id: parseFloat(cryptoWalletId),
      fiat_wallet_id: !isNaN(parseFloat(fiatWalletId))
        ? parseFloat(fiatWalletId)
        : null,
      setError,
      setIsLoading: setIsCreatingExchange,
    });
  }

  const setEstimateData = (data) => {
    dispatch(setEstimatedData(data));

    if (data.value) {
      setFiatAmount(Number(data.value).toFixed(2));
    }
  };

  useEffect(() => {
    const filtredList = getFiltredCurrencyList(fiatCurrenciesList);
    if (!filtredList.includes(fiatCurrency)) {
      setFiatCurrency(filtredList[0]);
    }
  }, []);

  useEffect(() => {
    if (!cryptoAmount) {
      setCryptoAmount(cryptoCurrency?.default_exchange_value);
    }
  }, [cryptoCurrency]);

  useEffect(() => {
    setFromAmountError(false);
  }, [from_amount]);

  useEffect(() => {
    if (fiatCurrency?.default_exchange_value) {
      setFiatAmount(fiatCurrency?.default_exchange_value);
    }

    if (cryptoCurrency?.default_exchange_value) {
      setCryptoAmount(cryptoCurrency?.default_exchange_value);
    }
  }, [from_currency, from_network]);

  useEffect(() => {
    const abortSignal = axios.CancelToken.source();

    if (from_currency && from_amount) {
      setFiatAmount('');
      dispatch(resetEstimatedData());

      estimateDebounced({
        onSuccess: setEstimateData,
        from_amount,
        from_currency,
        from_network,
        to_network,
        to_currency,
        setError,
        minMaxRange,
        setMinMaxRange,
        setFromAmountError,
        setAmount: setCryptoAmount,
        cancelToken: abortSignal.token,
      });
    }

    return () => abortSignal.cancel('Request cancelled');
  }, [from_amount, to_currency, from_currency, from_network, to_network]);

  const getCurrenciesWithNetworks = (data) => {
    return data.reduce((acc, item) => {
      item.networks.forEach((network) => {
        acc.push({
          id: item.ticker,
          title: network.name,
          currencyType: item.currency_type,
          default_exchange_value: item.default_exchange_value,
          network: network.network,
        });
      });

      return acc;
    }, []);
  };

  useEffect(() => {
    axios
      .get(`/b2b/currencies`)
      .then(
        ({
          data: {
            fiat_currencies: fiatCurrencies,
            crypto_currencies: cryptoCurrencies,
          },
        }) => {
          const mappedCurrencyData = [
            ...getCurrenciesWithNetworks(fiatCurrencies),
            ...getCurrenciesWithNetworks(cryptoCurrencies),
          ];
          const mappedCurrencyReversType = mappedCurrencyData.filter(
            (crr) => crr.currencyType !== mappedCurrencyData[0].currencyType
          );

          setCryptoCurrenciesList(mappedCurrencyData);
          setFiatCurrenciesList(mappedCurrencyReversType);
          setCurrenciesList(mappedCurrencyData);

          setFiatCurrency(mappedCurrencyReversType[0] || 'eur');
          setCryptoCurrency(mappedCurrencyData[0] || 'btc');
        }
      );
  }, [
    urlParams?.defaultCryptoCurrency,
    urlParams?.defaultCryptoNetwork,
    urlParams?.defaultFiatCurrency,
  ]);

  useEffect(() => {
    const revertCurrenciesData = currenciesList.filter(
      (crr) => crr.currencyType !== cryptoCurrency.currencyType
    );
    setFiatCurrenciesList(revertCurrenciesData);
    setFiatCurrency(revertCurrenciesData[0]);
  }, [cryptoCurrency]);

  return (
    <div className={cn(css.calculator)}>
      {!walletPage && (
        <>
          <CombinedInput
            inputClass={css.combinedInput}
            containerClass={css.combinedInputContainer}
            label="You send"
            {...formObj.from_currency.input}
            error={fromAmountError}
            pattern="\d*"
            appendedComponents={{
              select: (
                <Select
                  hasSearch
                  item={CurrencyItem}
                  className={css.inputSelect}
                  {...formObj.from_currency.select}
                />
              ),
              error: (
                <ErrorMessage
                  fromAmount={from_amount}
                  range={minMaxRange}
                  {...formObj.from_currency.error}
                />
              ),
            }}
          />

          <CombinedInput
            inputClass={css.combinedInput}
            containerClass={css.combinedInputContainer}
            readOnly
            label="You get"
            {...formObj.to_currency.input}
            value={to_amount ? `~${formatNumber(to_amount)}` : ''}
            error={error || fromAmountError}
            appendedComponents={{
              select: (
                <Select
                  hasSearch
                  item={CurrencyItem}
                  className={css.inputSelect}
                  {...formObj.to_currency.select}
                />
              ),
            }}
          />
        </>
      )}

      {kybStatus !== KYB_STATUSES.APPROVED && (
        <a className={css.becomePartnerButton} href="/b2b/kyb">
          Become a partner
        </a>
      )}
      {!walletPage && kybStatus === KYB_STATUSES.APPROVED && (
        <>
          <TransactionDetails />
          <Button
            fullWidth
            size="md"
            green
            id={'sell-button'}
            className={css.buyButton}
            disabled={!to_amount}
            onClick={() => {
              if (!termsAccepted) {
                setAgreementError(true);
                return;
              }
              if (termsAccepted && !fromAmountError) {
                setWalletPage(true);
              }
            }}
          >
            Exchange
          </Button>
        </>
      )}
      {walletPage &&
        termsAccepted &&
        !fromAmountError &&
        kybStatus === KYB_STATUSES.APPROVED &&
        cryptoCurrency?.currencyType === 'FIAT' && (
          <>
            <WalletsSelection
              onChange={onChangeHandler}
              setWalletId={setCryptoWalletId}
              selectedWallet={cryptoWalletId}
              ticker={fiatCurrency?.id}
              cryptoCurrency={cryptoCurrency}
              buttonLoader={
                isCreatingExchange ? (
                  <Loader className={css.loader} />
                ) : (
                  'Confirm'
                )
              }
              buttonAction={onSubmit}
              buttonClassName={css.buyButton}
              title={`To which wallet do you want to receive ${fiatCurrency?.id}`}
              titleMobile={`Receive ${fiatCurrency?.id} to this wallet`}
            />
            <div className={css.headerConfirm}>
              Confirm your <span>{fiatCurrency?.id}</span> wallet
            </div>
          </>
        )}

      {walletPage &&
        termsAccepted &&
        !fromAmountError &&
        kybStatus === KYB_STATUSES.APPROVED &&
        cryptoCurrency?.currencyType === 'CRYPTO' && (
          <>
            <WalletsSelection
              onChange={(e) => {
                setFiatWalletId(e.target.value);
              }}
              setWalletId={setFiatWalletId}
              selectedWallet={fiatWalletId}
              ticker={fiatCurrency?.id}
              cryptoCurrency={cryptoCurrency}
              buttonLoader={
                isCreatingExchange ? (
                  <Loader className={css.loader} />
                ) : (
                  'Confirm'
                )
              }
              buttonAction={onSubmit}
              buttonClassName={css.buyButton}
              title="Choose your bank account"
              titleMobile="Choose bank account"
            />

            <div className={css.headerConfirm}>
              Confirm <span>your</span> bank account
            </div>
          </>
        )}

      {!fromAmountError && error ? (
        <div className={css.error}>{error}</div>
      ) : null}
    </div>
  );
};

export { Calculator };
