export const mapEstimatedDataToDetails = (data) => () => {
  if (data) {
    return [
      {
        label: 'Estimated rate',
        value: `${parseFloat(data.estimated_exchange_rate).toFixed(8)} ${
          data.to_currency
        }/${data.from_currency}`,
      },
      {
        label: 'Arrival time',
        value: `~15 minutes`,
      },
    ];
  } else {
    return [
      {
        label: 'Estimated rate',
        value: `—`,
      },
      {
        label: 'Arrival time',
        value: `—`,
      },
    ];
  }
};
