import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import DetailsRow from './components/DetailsRow/DetailsRow';
import { useSelector } from 'react-redux';
import { mapEstimatedDataToDetails } from '../../pages/ExchangeDetails/utils';

const TransactionDetails = () => {
  const { estimatedData } = useSelector((state) => state.calculator);

  const details = useMemo(mapEstimatedDataToDetails(estimatedData), [
    estimatedData,
  ]);

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Transaction details</h2>
      {details && (
        <div className={styles.detailsWrapper}>
          {details.map((item, index) => (
            <DetailsRow
              label={item.label}
              value={item.value}
              noBorder={index === details.length - 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
