import { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';

const root = document.getElementById('calculator');

if (root instanceof HTMLElement) {
  const kybStatus = root.getAttribute('kyb-status');

  render(
    <StrictMode>
      <Provider store={store}>
        <App kybStatus={kybStatus} />
      </Provider>
    </StrictMode>,
    root,
  );
}
