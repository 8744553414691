import ExchangeDetails from './pages/ExchangeDetails/ExchangeDetails';
import './App.css';

export default function App({ kybStatus }) {
  return (
    <div className="App">
      <ExchangeDetails kybStatus={kybStatus} />
    </div> 
  );
}
