import React from 'react';
import styles from './styles.module.scss';
import { Calculator } from '../../components/Calculator/Calculator';

const ExchangeDetails = ({ kybStatus }) => (
    <div className={styles.container}>
      <Calculator kybStatus={kybStatus} />
    </div>
  );

export default ExchangeDetails;
