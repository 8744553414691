import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export const CurrencyItem = ({ item, isActive }) => {
  return (
    <div title={item.title} className={cn(styles.item, 'currency-item', { [styles.isActiveItem]: isActive })}>
      <img
        src={`https://changenow.io/images/sprite/currencies/${item.id.toLowerCase()}.svg`}
        className={styles.image}
        alt='ticker'
      />
      <div className='ticker'>
        {item.id}
        {item?.network && item?.network !== item.id && <sup>{item?.network}</sup>}
      </div>

      <div className='currency-name'>{item.title}</div>
    </div>
  );
};