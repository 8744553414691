import React from 'react';
import cn from 'classnames';

import css from './Button.module.scss';

function Button({
                    className,
                    children,
                    primary,
                    darkBlue,
                    blue,
                    white,
                    green,
                    grey,
                    outline,
                    primaryDark,
                    disabled,
                    size,
                    active,
                    fullWidth,
                    rounded = true,
                    onClick,
                    name,
                    id,
                    type = 'button',
                    noBorder
                }) {
    const buttonClassName = cn(css.button, className, {
        [css.rounded]: rounded,
        [css.primary]: primary,
        [css.active]: active,
        [css.darkBlue]: darkBlue,
        [css.blue]: blue,
        [css.white]: white,
        [css.green]: green,
        [css.outline]: outline,
        [css.primaryDark]: primaryDark,
        [css.fullWidth]: fullWidth,
        [css.grey]: grey,
        [css.md]: size === 'md',
        [css.noBorder]: noBorder
    });

    return (
        <button type={type} className={buttonClassName} onClick={onClick} disabled={disabled} name={name} id={id}>
            {children}
        </button>
    );
}

export { Button };
